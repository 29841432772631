export function populateDropdown(dropdown, data) {
    data.forEach((item) => {
      const option = document.createElement("option");
      option.value = item.id;
      option.text = item.name;
      dropdown.appendChild(option);
    });
  }

export function handleRegionSelect() {

    const regionDropdown = document.getElementById("region-dropdown");

    regionDropdown.addEventListener("change", function() {
      const selectedValue = regionDropdown.value;
      populateCountryDropdown(selectedValue);

    });
  }

export function populateCountryDropdown(selectedRegion) {
    const countryDropdown = document.getElementById("country-dropdown");

    const url = `/locations/retrieve_children_of_location?id=${selectedRegion}`;

    countryDropdown.innerHTML = `<option value="">Select Country</option>`;
    countryDropdown.disabled = false;

    fetch(url)
      .then((response) => response.json())
      .then((data) => {
        populateDropdown(countryDropdown, data);
        const selectedCountryId = countryDropdown.dataset.selectedCountryId;
        const options = countryDropdown.getElementsByTagName("option");
        for (let i = 0; i < options.length; i++) {
        if (options[i].value === selectedCountryId) {
          options[i].selected = true;
          const event = new Event('change');
          countryDropdown.dispatchEvent(event);
          break; // Stop the loop once the corresponding option is found and selected
      }
    }
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
    



  }