// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from "@rails/ujs"
import Turbolinks from "turbolinks"
import * as ActiveStorage from "@rails/activestorage"
import "channels"
require("location_functions")

Rails.start()
// comment Turbolinks.start() out since the javascript wasn't loading properly
// when accessing the new.haml locations page via link_to from show.haml locations page
// Turbolinks.start()
ActiveStorage.start()
